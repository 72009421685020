import React from 'react'

export function NotFound() {
  return (
    <>
      <div className="center">
        <h1>404</h1>
        <h2>Oops. Page not found.</h2>
      </div>
    </>
  )
}
